import {
  UserDetail,
  memberActions,
  CostCenter,
  GET_COST_CENTER_SUCCESS,
  GET_COST_CENTER_ERROR,
  Companies,
  GET_COMPANIES_SUCCESS,
  GET_COMPANIES_ERROR,
  GET_CURRENT_DEBT_LİST_SUCCESS,
  GET_CURRENT_DEBT_LİST_ERROR,
  CurrentDebt,
  GET_MY_USER_DETAIL_SUCCESS,
  GET_MY_USER_DETAIL_ERROR,
  MainCostCenter,
  GET_MAİN_COST_CENTER_GROUP_SUCCESS,
  GET_MAİN_COST_CENTER_GROUP_ERROR,
  SubCostCenter,
  GET_SUB_COST_CENTER_GROUP_ERROR,
  GET_SUB_COST_CENTER_GROUP_SUCCESS,
  CostCenterList,
  GET_COST_CENTER_LİST_ERROR,
  GET_COST_CENTER_LİST_SUCCESS,
  GET_COST_CENTER_DETAIL_SUCCESS,
  GET_COST_CENTER_DETAIL_ERROR,
  CostCenterDetail,
  Firm,
  GET_FİRM_NAMES_ERROR,
  GET_FİRM_NAMES_SUCCESS,
  GET_CUSTOMER_LİST_BY_COMPANYID_ERROR,
  GET_CUSTOMER_LİST_BY_COMPANYID_SUCCESS,
  Customer,
  Salary,
  GET_PERSONNEL_SALARY_LIST_SUCCESS,
  GET_PERSONNEL_SALARY_LIST_ERROR,
  AllPersonnel,
  GET_ALL_PERSONNEL_LİST_ERROR,
  GET_ALL_PERSONNEL_LİST_SUCCESS,
  GET_COST_CENTER_TREE_LİST_ERROR,
  GET_COST_CENTER_TREE_LİST_SUCCESS,
  GET_MAİN_COST_CENTER_LİST_ERROR,
  GET_MAİN_COST_CENTER_LİST_SUCCESS,
  GET_SUB_COST_CENTER_LİST_ERROR,
  GET_SUB_COST_CENTER_LİST_SUCCESS,
  MainCostCenterList,
  SubCostCenterList,
  CostCenterTreeList,
  CompanybyInput,
  CustomerbyInput,
  GET_COMPANY_LİST_BY_INPUT_ERROR,
  GET_COMPANY_LİST_BY_INPUT_SUCCESS,
  GET_CUSTOMER_LİST_BY_INPUT_ERROR,
  GET_CUSTOMER_LİST_BY_INPUT_SUCCESS,
  GET_SALARY_DETAİL_LİST_SUCCESS,
  GET_SALARY_DETAİL_LİST_ERROR,
  SalaryDetailList,
  PersonelListBySubStatus,
  GET_PERSONEL_LİST_BY_SUB_STATUS_SUCCESS,
  GET_PERSONEL_LİST_BY_SUB_STATUS_ERROR,
  GET_PERSONEL_LİST_BY_SALARY_DETAİL_ERROR,
  GET_PERSONEL_LİST_BY_SALARY_DETAİL_SUCCESS,
  PersonelListBySalaryDetail,
  GET_CURRENT_DEBT_REPORT_BY_COST_CENTER_CODE_ERROR,
  GET_CURRENT_DEBT_REPORT_BY_COST_CENTER_CODE_SUCCESS,
  GET_CURRENT_DEBT_REPORT_BY_DETAİL_ERROR,
  GET_CURRENT_DEBT_REPORT_BY_DETAİL_SUCCESS,
  GET_CURRENT_DEBT_REPORT_BY_MAİN_CODE_ERROR,
  GET_CURRENT_DEBT_REPORT_BY_MAİN_CODE_SUCCESS,
  GET_CURRENT_DEBT_REPORT_BY_SUB_CODE_ERROR,
  GET_CURRENT_DEBT_REPORT_BY_SUB_CODE_SUCCESS,
  CurrentDebtReportbyCostCenterCode,
  CurrentDebtReportbyDetail,
  CurrentDebtReportbySubCode,
  CurrentDebtReportbyMainCode,
  PersonnelListForLastYear,
  GET_PERSONNEL_LİST_FOR_LAST_YEAR_ERROR,
  GET_PERSONNEL_LİST_FOR_LAST_YEAR_SUCCESS,
  GET_CURRENT_PERİOD_REPORT_BY_COMPANY_SUCCESS,
  GET_CURRENT_PERİOD_REPORT_BY_COMPANY_ERROR,
  CurrentPeriodReportbyCompany,
  GET_CURRENT_PERİOD_REPORT_BY_MONTH_ERROR,
  GET_CURRENT_PERİOD_REPORT_BY_MONTH_SUCCESS,
  GET_CURRENT_PERİOD_REPORT_BY_FİRM_SUCCESS,
  GET_CURRENT_PERİOD_REPORT_BY_FİRM_ERROR,
  CurrentPeriodReportbyFirm,
  CurrentPeriodReportbyMonth,
  GET_EMPLOYEES_BY_INPUT_SUCCESS,
  GET_EMPLOYEES_BY_INPUT_ERROR,
  EmployeesByInput,
  TechnicGroupMainList,
  GET_TECHNİC_GROUP_MAİN_LİST_ERROR,
  GET_TECHNİC_GROUP_MAİN_LİST_SUCCESS,
  TechnicalList,
  GET_ALL_TECHNICAL_GROUP_SUCCESS,
  GET_ALL_TECHNICAL_GROUP_ERROR,
  ProfilabilityByCandidateList,
  SelectionPlacementByCandidateList,
  GET_SELECTION_PLACEMENT_BY_CANDIDATE_SUCCESS,
  GET_SELECTION_PLACEMENT_BY_CANDIDATE_ERROR,
  GET_PROFILABILITY_BY_CANDIDATE_SUCCESS,
  GET_PROFILABILITY_BY_CANDIDATE_ERROR,
  CandidateList,
  GET_CANDIDATE_LIST_SUCCESS,
  GET_CANDIDATE_LIST_ERROR,
  RecruiterList,
  GET_RECRUITER_LIST_SUCCESS,
  GET_RECRUITER_LIST_ERROR,
  GET_OUTSOURCE_PERSONNEL_LİST_SUCCESS,
  GET_OUTSOURCE_PERSONNEL_LİST_ERROR,
  OutsourcePersonnel,
  GET_COMPANIES_BY_MEMBER_ID_SUCCESS,
  GET_COMPANIES_BY_MEMBER_ID_ERROR,
  CompaniesbyMemberId,
  CustomerbyProfitability,
  GET_CUSTOMER_BY_PROFİTABİLİTY_SUCCESS,
  GET_CUSTOMER_BY_PROFİTABİLİTY_ERROR,
  GET_NETTİNG_LİST_SUCCESS,
  GET_NETTİNG_LİST_ERROR,
  Netting,
  GET_POSİTİON_LİST_SUCCESS,
  GET_POSİTİON_LİST_ERROR,
  PositionList,
  SalarySummaryList,
  GET_SALARY_SUMMARY_LİST_ERROR,
  GET_SALARY_SUMMARY_LİST_SUCCESS,
  BudgetByMainCode,
  GET_BUDGET_BY_MAIN_CODE_ERROR,
  GET_BUDGET_BY_MAIN_CODE_SUCCESS,
  GET_BUDGET_BY_SUB_CODE_ERROR,
  GET_BUDGET_BY_SUB_CODE_SUCCESS,
  BudgetBySubCode,
  BudgetByCostCenterCode,
  GET_BUDGET_BY_COST_CENTER_CODE_SUCCESS,
  GET_BUDGET_BY_COST_CENTER_CODE_ERROR,
  GET_BUDGET_GENERAL_REPORT_ERROR,
  GET_BUDGET_GENERAL_REPORT_SUCCESS,
  BudgetGeneralReport,
  GET_BUDGET_MAIN_REPORT_ERROR,
  GET_BUDGET_MAIN_REPORT_SUCCESS,
  BudgetMainReport,
  GET_BUDGET_SUB_REPORT_ERROR,
  GET_BUDGET_SUB_REPORT_SUCCESS,
  BudgetSubReport,
  GET_BUDGET_DETAIL_REPORT_ERROR,
  GET_BUDGET_DETAIL_REPORT_SUCCESS,
  BudgetDetailReport,
  StatusStatisticList,
  ExchangeBankRateList,
  GET_STATUS_STATİSTİC_LİST_SUCCESS,
  GET_STATUS_STATİSTİC_LİST_ERROR,
  StatusStatisticbyMemberList,
  GET_STATUS_STATİSTİC_BY_MEMBER_LİST_SUCCESS,
  GET_STATUS_STATİSTİC_BY_MEMBER_LİST_ERROR,
  GET_BUDGET_FORECAST_BY_MAINCODE_LİST_SUCCESS,
  GET_BUDGET_FORECAST_BY_MAINCODE_LİST_ERROR,
  GET_BUDGET_FORECAST_BY_SUBCODE_LİST_SUCCESS,
  GET_BUDGET_FORECAST_BY_SUBCODE_LİST_ERROR,
  BudgetForeCastbyMainCodeList,
  BudgetForeCastbySubCodeList,
  GET_BUDGET_FORECAST_BY_COSTCENTERCODE_LİST_SUCCESS,
  GET_BUDGET_FORECAST_BY_COSTCENTERCODE_LİST_ERROR,
  BudgetForeCastbyCostCenterCodeList,
  GET_EXCHANGE_BANK_RATES_LİST_SUCCESS,
  GET_EXCHANGE_BANK_RATES_LİST_ERROR,

} from "../../redux/actions/actionTypes";

//state'mizi oluşturuyoruz. Dataları burada barındırıypruz. TaskState'tipinde olduğunu belittyoruz.

interface CostCenterState {
  costCenterList: CostCenter[];
  message: string;
}

const initialStateCostCenter: CostCenterState = {
  costCenterList: [],
  message: "",
};

interface CompaniesState {
  companiesList: Companies[];
  message: string;
}

const initialStateCompanies: CompaniesState = {
  companiesList: [],
  message: "",
};

interface CurrentDebtState {
  currentDebtList: CurrentDebt[];
  message: string;
}

const initialStateCurrentDebt: CurrentDebtState = {
  currentDebtList: [],
  message: "",
};

interface MyUserDetailListState {
  myUserDetailList: UserDetail[];
  message: string;
}

const initialStateMyUserDetail: MyUserDetailListState = {
  myUserDetailList: [],
  message: "",
};

interface MainCostCenterState {
  mainCostCenterList: MainCostCenter[];
  message: string;
}

const initialStateMainCostCenter: MainCostCenterState = {
  mainCostCenterList: [],
  message: "",
};

interface SubCostCenterState {
  subCostCenterList: SubCostCenter[];
  message: string;
}

const initialStateSubCostCenter: SubCostCenterState = {
  subCostCenterList: [],
  message: "",
};
interface CostCenterListState {
  costAllCenterList: CostCenterList[];
  message: string;
}

const initialStateCostCenterList: CostCenterListState = {
  costAllCenterList: [],
  message: "",
};

interface CostCenterDetailState {
  costCenterDetail: CostCenterDetail[];
  message: string;
}

const initialStateCostCenterDetail: CostCenterDetailState = {
  costCenterDetail: [],
  message: "",
};
interface FirmState {
  firm: Firm[];
  message: string;
}

const initialStateFirm: FirmState = {
  firm: [],
  message: "",
};

interface CustomerState {
  customerList: Customer[];
  message: string;
}

const initialStateCustomer: CustomerState = {
  customerList: [],
  message: "",
};

interface SalaryState {
  salaryList: Salary[];
  message: string;
}

const initialStateSalary: SalaryState = {
  salaryList: [],
  message: "",
};

interface PersonnelAllState {
  personnelAllList: AllPersonnel[];
  message: string;
}
const initialStateAllPersonnel: PersonnelAllState = {
  personnelAllList: [],
  message: "",
};

interface MainCostCenterTreeState {
  mainCostCenterTreeList: MainCostCenterList[];
  message: string;
}

const initialStateMainCostCenterTree: MainCostCenterTreeState = {
  mainCostCenterTreeList: [],
  message: "",
};

interface SubCostCenterTreeState {
  subCostCenterTreeList: SubCostCenterList[];
  message: string;
}

const initialStateSubCostCenterTree: SubCostCenterTreeState = {
  subCostCenterTreeList: [],
  message: "",
};

interface CostCenterTreeState {
  costCenterTreeList: CostCenterTreeList[];
  message: string;
}

const initialStateCostCenterTree: CostCenterTreeState = {
  costCenterTreeList: [],
  message: "",
};

interface CustomerbyInputState {
  customerbyInputList: CustomerbyInput[];
  message: string;
}

const initialStateCustomerbyInput: CustomerbyInputState = {
  customerbyInputList: [],
  message: "",
};

interface CompanybyInputState {
  companybyInputList: CompanybyInput[];
  message: string;
}

const initialStateCompanybyInput: CompanybyInputState = {
  companybyInputList: [],
  message: "",
};

interface SalaryDetailListState {
  salaryDetailList: SalaryDetailList[];
  message: string;
}

const initialStateSalaryDetailList: SalaryDetailListState = {
  salaryDetailList: [],
  message: "",
};

interface PersonelListBySubStatusState {
  personelListBySubStatus: PersonelListBySubStatus[];
  message: string;
}

const initialStatePersonelListBySubStatus: PersonelListBySubStatusState = {
  personelListBySubStatus: [],
  message: "",
};

interface PersonelListBySalaryDetailState {
  personelListBySalaryDetail: PersonelListBySalaryDetail[];
  message: string;
}

const initialStatePersonelListBySalaryDetail: PersonelListBySalaryDetailState =
{
  personelListBySalaryDetail: [],
  message: "",
};

interface CurrentDebtReportbyMainCodeState {
  currentDebtReportbyMainCode: CurrentDebtReportbyMainCode[];
  message: string;
}

const initialStateCurrentDebtReportbyMainCodeState: CurrentDebtReportbyMainCodeState =
{
  currentDebtReportbyMainCode: [],
  message: "",
};

interface CurrentDebtReportbySubCodeState {
  currentDebtReportbySubCode: CurrentDebtReportbySubCode[];
  message: string;
}

const initialStateCurrentDebtReportbySubCodeState: CurrentDebtReportbySubCodeState =
{
  currentDebtReportbySubCode: [],
  message: "",
};

interface CurrentDebtReportbyCostCenterCodeState {
  currentDebtReportbyCostCenterCode: CurrentDebtReportbyCostCenterCode[];
  message: string;
}

const initialStateCurrentDebtReportbyCostCenterCodeState: CurrentDebtReportbyCostCenterCodeState =
{
  currentDebtReportbyCostCenterCode: [],
  message: "",
};

interface CurrentDebtReportbyDetailState {
  currentDebtReportbyDetail: CurrentDebtReportbyDetail[];
  message: string;
}

const initialStateCurrentDebtReportbyDetailState: CurrentDebtReportbyDetailState =
{
  currentDebtReportbyDetail: [],
  message: "",
};

interface PersonelListForLastYearState {
  personnelListForLastYear: PersonnelListForLastYear[];
  message: string;
}
const initialStatePersonnelListForLastYear: PersonelListForLastYearState = {
  personnelListForLastYear: [],
  message: "",
};

interface CurrentPeriodReportbyCompanyState {
  currentPeriodReportbyCompany: CurrentPeriodReportbyCompany[];
  message: string;
}
const initialStateCurrentPeriodReportbyCompany: CurrentPeriodReportbyCompanyState =
{
  currentPeriodReportbyCompany: [],
  message: "",
};
interface CurrentPeriodReportbyMonthState {
  currentPeriodReportbyMonth: CurrentPeriodReportbyMonth[];
  message: string;
}
const initialStateCurrentPeriodReportbyMonth: CurrentPeriodReportbyMonthState =
{
  currentPeriodReportbyMonth: [],
  message: "",
};
interface CurrentPeriodReportbyFirmState {
  currentPeriodReportbyFirm: CurrentPeriodReportbyFirm[];
  message: string;
}
const initialStateCurrentPeriodReportbyFirm: CurrentPeriodReportbyFirmState = {
  currentPeriodReportbyFirm: [],
  message: "",
};
interface EmployeesByInputState {
  employeesByInput: EmployeesByInput[];
  message: string;
}
const initialStateEmployeesByInput: EmployeesByInputState = {
  employeesByInput: [],
  message: "",
};
interface TechnicGroupMainListState {
  technicGroupMainList: TechnicGroupMainList[];
  message: string;
}

const initialStateTechnicGroupMainList: TechnicGroupMainListState = {
  technicGroupMainList: [],
  message: "",
};
interface TechnicalGroupState {
  technicalList: TechnicalList[];
  message: string;
}
const initialStateTechnicalGroup: TechnicalGroupState = {
  technicalList: [],
  message: "",
};
interface ProfilabilityByCandidateState {
  profilabilityByCandidateList: ProfilabilityByCandidateList[];
  message: string;
}
const initialStateProfilabilityByCandidate: ProfilabilityByCandidateState = {
  profilabilityByCandidateList: [],
  message: "",
};
interface SelectionPlacementByCandidateState {
  selectionPlacementByCandidateList: SelectionPlacementByCandidateList[];
  message: string;
}
const initialStateSelctionPlacementByCandidate: SelectionPlacementByCandidateState =
{
  selectionPlacementByCandidateList: [],
  message: "",
};
interface CandidateListState {
  candidateList: CandidateList[];
  message: string;
}
const initialStateCandidateList: CandidateListState = {
  candidateList: [],
  message: "",
};
interface RecruiterListState {
  recruiterList: RecruiterList[];
  message: string;
}
const initialStateRecruiterlist: RecruiterListState = {
  recruiterList: [],
  message: "",
};
interface OutsourcePersonnelListState {
  outsourcePersonnel: OutsourcePersonnel[];
  message: string;
}
const initialStateOutsourcePersonnelList: OutsourcePersonnelListState = {
  outsourcePersonnel: [],
  message: "",
};

interface CompaniesbyMemberIdListState {
  companiesbyMemberId: CompaniesbyMemberId[];
  message: string;
}
const initialStateCompaniesbyMemberIdList: CompaniesbyMemberIdListState = {
  companiesbyMemberId: [],
  message: "",
};

interface CustomerByProfitabilityState {
  customerbyProfitability: CustomerbyProfitability[];
  message: string;
}
const initialStateCustomerbyProfitability: CustomerByProfitabilityState = {
  customerbyProfitability: [],
  message: "",
};

interface NettingListState {
  netting: Netting[];
  message: string;
}
const initialStateNettingList: NettingListState = {
  netting: [],
  message: "",
};

interface PositionListState {
  positionList: PositionList[];
  message: string;
}
const initialStatePositionList: PositionListState = {
  positionList: [],
  message: "",
};

interface SalarySummaryListState {
  salarySummaryList: SalarySummaryList[];
  message: string;
}
const initialStateSalarySummaryList: SalarySummaryListState = {
  salarySummaryList: [],
  message: "",
};

interface BudgetByMainCodeState {
  budgetByMainCode: BudgetByMainCode[];
  message: string;
}

const initialStateBudgetByMainCodeState: BudgetByMainCodeState = {
  budgetByMainCode: [],
  message: "",
};

interface BudgetBySubCodeState {
  budgetBySubCode: BudgetBySubCode[];
  message: string;
}

const initialStateBudgetBySubCodeState: BudgetBySubCodeState = {
  budgetBySubCode: [],
  message: "",
};

interface BudgetByCostCenterCodeState {
  budgetByCostCenterCode: BudgetByCostCenterCode[];
  message: string;
}

const initialStateBudgetByCostCenterCodeState: BudgetByCostCenterCodeState = {
  budgetByCostCenterCode: [],
  message: "",
};

interface BudgetGeneralReportState {
  budgetGeneralReport: BudgetGeneralReport[];
  message: string;
}

const initialStateBudgetGeneralReportState: BudgetGeneralReportState = {
  budgetGeneralReport: [],
  message: "",
};

interface BudgetMainReportState {
  budgetMainReport: BudgetMainReport[];
  message: string;
}

const initialStateBudgetMainReportState: BudgetMainReportState = {
  budgetMainReport: [],
  message: "",
};

interface BudgetSubReportState {
  budgetSubReport: BudgetSubReport[];
  message: string;
}

const initialStateBudgetSubReportState: BudgetSubReportState = {
  budgetSubReport: [],
  message: "",
};

interface BudgetDetailReportState {
  budgetDetailReport: BudgetDetailReport[];
  message: string;
}

const initialStateBudgetDetailReportState: BudgetDetailReportState = {
  budgetDetailReport: [],
  message: "",
};

interface StatusStatisticListState {
  statusStatisticList: StatusStatisticList[];
  message: string;
}


const initialStateStatusStatisticList: StatusStatisticListState = {
  statusStatisticList: [],
  message: "",
};

interface ExchangeBankRateListState {
  exchangeBankRateList:ExchangeBankRateList[];
  message: string;
}

const initialStateExchangeBankRateList: ExchangeBankRateListState={
  exchangeBankRateList:[],
  message:"",
}
interface StatusStatisticbyMemberListState {
  statusStatisticbyMemberList: StatusStatisticbyMemberList[];
  message: string;
}

const initialStateStatusStatisticbyMemberList: StatusStatisticbyMemberListState =
{
  statusStatisticbyMemberList: [],
  message: "",
};

interface BudgetForeCastbyMainCodeListState {
  budgetForeCastbyMainCodeList: BudgetForeCastbyMainCodeList[];
  message: string;
}

const initialStateBudgetForeCastbyMainCode: BudgetForeCastbyMainCodeListState =
{
  budgetForeCastbyMainCodeList: [],
  message: "",
};

interface BudgetForeCastbySubCodeListState {
  budgetForeCastbySubCodeList: BudgetForeCastbySubCodeList[];
  message: string;
}

const initialStateBudgetForeCastbySubCode: BudgetForeCastbySubCodeListState =
{
  budgetForeCastbySubCodeList: [],
  message: "",
};

interface BudgetForeCastbyCostCenterCodeListState {
  budgetForeCastbyCostCenterCodeList: BudgetForeCastbyCostCenterCodeList[];
  message: string;
}

const initialStateBudgetForeCastbyCostCenterCode: BudgetForeCastbyCostCenterCodeListState =
{
  budgetForeCastbyCostCenterCodeList: [],
  message: "",
};

export function costCenterReducer(
  state: CostCenterState = initialStateCostCenter,
  action: memberActions
): CostCenterState {
  switch (action.type) {
    case GET_COST_CENTER_SUCCESS: {
      return {
        ...state,
        costCenterList: action.payload,
      };
    }
    case GET_COST_CENTER_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function companiesReducer(
  state: CompaniesState = initialStateCompanies,
  action: memberActions
): CompaniesState {
  switch (action.type) {
    case GET_COMPANIES_SUCCESS: {
      return {
        ...state,
        companiesList: action.payload,
      };
    }
    case GET_COMPANIES_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function currentDebtReducer(
  state: CurrentDebtState = initialStateCurrentDebt,
  action: memberActions
): CurrentDebtState {
  switch (action.type) {
    case GET_CURRENT_DEBT_LİST_SUCCESS: {
      return {
        ...state,
        currentDebtList: action.payload,
      };
    }
    case GET_CURRENT_DEBT_LİST_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function myUserDetailReducer(
  state: MyUserDetailListState = initialStateMyUserDetail,
  action: memberActions
): MyUserDetailListState {
  switch (action.type) {
    case GET_MY_USER_DETAIL_SUCCESS: {
      return {
        ...state,
        myUserDetailList: action.payload,
      };
    }
    case GET_MY_USER_DETAIL_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function mainCostCenterReducer(
  state: MainCostCenterState = initialStateMainCostCenter,
  action: memberActions
): MainCostCenterState {
  switch (action.type) {
    case GET_MAİN_COST_CENTER_GROUP_SUCCESS: {
      return {
        ...state,
        mainCostCenterList: action.payload,
      };
    }
    case GET_MAİN_COST_CENTER_GROUP_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function subCostCenterReducer(
  state: SubCostCenterState = initialStateSubCostCenter,
  action: memberActions
): SubCostCenterState {
  switch (action.type) {
    case GET_SUB_COST_CENTER_GROUP_SUCCESS: {
      return {
        ...state,
        subCostCenterList: action.payload,
      };
    }
    case GET_SUB_COST_CENTER_GROUP_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function allCostCenterReducer(
  state: CostCenterListState = initialStateCostCenterList,
  action: memberActions
): CostCenterListState {
  switch (action.type) {
    case GET_COST_CENTER_LİST_SUCCESS: {
      return {
        ...state,
        costAllCenterList: action.payload,
      };
    }
    case GET_COST_CENTER_LİST_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function costCenterDetailReducer(
  state: CostCenterDetailState = initialStateCostCenterDetail,
  action: memberActions
): CostCenterDetailState {
  switch (action.type) {
    case GET_COST_CENTER_DETAIL_SUCCESS: {
      return {
        ...state,
        costCenterDetail: action.payload,
      };
    }
    case GET_COST_CENTER_DETAIL_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function firmReducer(
  state: FirmState = initialStateFirm,
  action: memberActions
): FirmState {
  switch (action.type) {
    case GET_FİRM_NAMES_SUCCESS: {
      return {
        ...state,
        firm: action.payload,
      };
    }
    case GET_FİRM_NAMES_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function customerReducer(
  state: CustomerState = initialStateCustomer,
  action: memberActions
): CustomerState {
  switch (action.type) {
    case GET_CUSTOMER_LİST_BY_COMPANYID_SUCCESS: {
      return {
        ...state,
        customerList: action.payload,
      };
    }
    case GET_CUSTOMER_LİST_BY_COMPANYID_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function salaryListReducer(
  state: SalaryState = initialStateSalary,
  action: memberActions
): SalaryState {
  switch (action.type) {
    case GET_PERSONNEL_SALARY_LIST_SUCCESS: {
      return {
        ...state,
        salaryList: action.payload,
      };
    }
    case GET_PERSONNEL_SALARY_LIST_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function allPersonnelReducer(
  state: PersonnelAllState = initialStateAllPersonnel,
  action: memberActions
): PersonnelAllState {
  switch (action.type) {
    case GET_ALL_PERSONNEL_LİST_SUCCESS: {
      return {
        ...state,
        personnelAllList: action.payload,
      };
    }
    case GET_ALL_PERSONNEL_LİST_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function mainCostCenterListReducer(
  state: MainCostCenterTreeState = initialStateMainCostCenterTree,
  action: memberActions
): MainCostCenterTreeState {
  switch (action.type) {
    case GET_MAİN_COST_CENTER_LİST_SUCCESS: {
      return {
        ...state,
        mainCostCenterTreeList: action.payload,
      };
    }
    case GET_MAİN_COST_CENTER_LİST_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function subCostCenterListReducer(
  state: SubCostCenterTreeState = initialStateSubCostCenterTree,
  action: memberActions
): SubCostCenterTreeState {
  switch (action.type) {
    case GET_SUB_COST_CENTER_LİST_SUCCESS: {
      return {
        ...state,
        subCostCenterTreeList: action.payload,
      };
    }
    case GET_SUB_COST_CENTER_LİST_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function costCenterListReducer(
  state: CostCenterTreeState = initialStateCostCenterTree,
  action: memberActions
): CostCenterTreeState {
  switch (action.type) {
    case GET_COST_CENTER_TREE_LİST_SUCCESS: {
      return {
        ...state,
        costCenterTreeList: action.payload,
      };
    }
    case GET_COST_CENTER_TREE_LİST_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function customerbyInputReducer(
  state: CustomerbyInputState = initialStateCustomerbyInput,
  action: memberActions
): CustomerbyInputState {
  switch (action.type) {
    case GET_CUSTOMER_LİST_BY_INPUT_SUCCESS: {
      return {
        ...state,
        customerbyInputList: action.payload,
      };
    }
    case GET_CUSTOMER_LİST_BY_INPUT_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function companybyInputReducer(
  state: CompanybyInputState = initialStateCompanybyInput,
  action: memberActions
): CompanybyInputState {
  switch (action.type) {
    case GET_COMPANY_LİST_BY_INPUT_SUCCESS: {
      return {
        ...state,
        companybyInputList: action.payload,
      };
    }
    case GET_COMPANY_LİST_BY_INPUT_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function salaryDetailListReducer(
  state: SalaryDetailListState = initialStateSalaryDetailList,
  action: memberActions
): SalaryDetailListState {
  switch (action.type) {
    case GET_SALARY_DETAİL_LİST_SUCCESS: {
      return {
        ...state,
        salaryDetailList: action.payload,
      };
    }
    case GET_SALARY_DETAİL_LİST_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function personelListBySubStatusReducer(
  state: PersonelListBySubStatusState = initialStatePersonelListBySubStatus,
  action: memberActions
): PersonelListBySubStatusState {
  switch (action.type) {
    case GET_PERSONEL_LİST_BY_SUB_STATUS_SUCCESS: {
      return {
        ...state,
        personelListBySubStatus: action.payload,
      };
    }
    case GET_PERSONEL_LİST_BY_SUB_STATUS_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function personelListBySalaryDetailReducer(
  state: PersonelListBySalaryDetailState = initialStatePersonelListBySalaryDetail,
  action: memberActions
): PersonelListBySalaryDetailState {
  switch (action.type) {
    case GET_PERSONEL_LİST_BY_SALARY_DETAİL_SUCCESS: {
      return {
        ...state,
        personelListBySalaryDetail: action.payload,
      };
    }
    case GET_PERSONEL_LİST_BY_SALARY_DETAİL_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function currentDebtReportbyMainCodeReducer(
  state: CurrentDebtReportbyMainCodeState = initialStateCurrentDebtReportbyMainCodeState,
  action: memberActions
): CurrentDebtReportbyMainCodeState {
  switch (action.type) {
    case GET_CURRENT_DEBT_REPORT_BY_MAİN_CODE_SUCCESS: {
      return {
        ...state,
        currentDebtReportbyMainCode: action.payload,
      };
    }
    case GET_CURRENT_DEBT_REPORT_BY_MAİN_CODE_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function currentDebtReportbySubCodeReducer(
  state: CurrentDebtReportbySubCodeState = initialStateCurrentDebtReportbySubCodeState,
  action: memberActions
): CurrentDebtReportbySubCodeState {
  switch (action.type) {
    case GET_CURRENT_DEBT_REPORT_BY_SUB_CODE_SUCCESS: {
      return {
        ...state,
        currentDebtReportbySubCode: action.payload,
      };
    }
    case GET_CURRENT_DEBT_REPORT_BY_SUB_CODE_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function currentDebtReportbyCostCenterCodeReducer(
  state: CurrentDebtReportbyCostCenterCodeState = initialStateCurrentDebtReportbyCostCenterCodeState,
  action: memberActions
): CurrentDebtReportbyCostCenterCodeState {
  switch (action.type) {
    case GET_CURRENT_DEBT_REPORT_BY_COST_CENTER_CODE_SUCCESS: {
      return {
        ...state,
        currentDebtReportbyCostCenterCode: action.payload,
      };
    }
    case GET_CURRENT_DEBT_REPORT_BY_COST_CENTER_CODE_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function currentDebtReportbyDetailReducer(
  state: CurrentDebtReportbyDetailState = initialStateCurrentDebtReportbyDetailState,
  action: memberActions
): CurrentDebtReportbyDetailState {
  switch (action.type) {
    case GET_CURRENT_DEBT_REPORT_BY_DETAİL_SUCCESS: {
      return {
        ...state,
        currentDebtReportbyDetail: action.payload,
      };
    }
    case GET_CURRENT_DEBT_REPORT_BY_DETAİL_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function personelListForLastYearReducer(
  state: PersonelListForLastYearState = initialStatePersonnelListForLastYear,
  action: memberActions
): PersonelListForLastYearState {
  switch (action.type) {
    case GET_PERSONNEL_LİST_FOR_LAST_YEAR_SUCCESS: {
      return {
        ...state,
        personnelListForLastYear: action.payload,
      };
    }
    case GET_PERSONNEL_LİST_FOR_LAST_YEAR_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function currentPeriodReportbyCompanyReducer(
  state: CurrentPeriodReportbyCompanyState = initialStateCurrentPeriodReportbyCompany,
  action: memberActions
): CurrentPeriodReportbyCompanyState {
  switch (action.type) {
    case GET_CURRENT_PERİOD_REPORT_BY_COMPANY_SUCCESS: {
      return {
        ...state,
        currentPeriodReportbyCompany: action.payload,
      };
    }
    case GET_CURRENT_PERİOD_REPORT_BY_COMPANY_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}
export function currentPeriodReportbyMonthReducer(
  state: CurrentPeriodReportbyMonthState = initialStateCurrentPeriodReportbyMonth,
  action: memberActions
): CurrentPeriodReportbyMonthState {
  switch (action.type) {
    case GET_CURRENT_PERİOD_REPORT_BY_MONTH_SUCCESS: {
      return {
        ...state,
        currentPeriodReportbyMonth: action.payload,
      };
    }
    case GET_CURRENT_PERİOD_REPORT_BY_MONTH_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}
export function currentPeriodReportbyFirmReducer(
  state: CurrentPeriodReportbyFirmState = initialStateCurrentPeriodReportbyFirm,
  action: memberActions
): CurrentPeriodReportbyFirmState {
  switch (action.type) {
    case GET_CURRENT_PERİOD_REPORT_BY_FİRM_SUCCESS: {
      return {
        ...state,
        currentPeriodReportbyFirm: action.payload,
      };
    }
    case GET_CURRENT_PERİOD_REPORT_BY_FİRM_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}
export function employeesByInputReducer(
  state: EmployeesByInputState = initialStateEmployeesByInput,
  action: memberActions
): EmployeesByInputState {
  switch (action.type) {
    case GET_EMPLOYEES_BY_INPUT_SUCCESS: {
      return {
        ...state,
        employeesByInput: action.payload,
      };
    }
    case GET_EMPLOYEES_BY_INPUT_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}
export function technicGroupMainListReducer(
  state: TechnicGroupMainListState = initialStateTechnicGroupMainList,
  action: memberActions
): TechnicGroupMainListState {
  switch (action.type) {
    case GET_TECHNİC_GROUP_MAİN_LİST_SUCCESS: {
      return {
        ...state,
        technicGroupMainList: action.payload,
      };
    }
    case GET_TECHNİC_GROUP_MAİN_LİST_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}
export function technicalReducer(
  state: TechnicalGroupState = initialStateTechnicalGroup,
  action: memberActions
): TechnicalGroupState {
  switch (action.type) {
    case GET_ALL_TECHNICAL_GROUP_SUCCESS: {
      return {
        ...state,
        technicalList: action.payload,
      };
    }
    case GET_ALL_TECHNICAL_GROUP_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}
export function profilabilityByCandidateReducer(
  state: ProfilabilityByCandidateState = initialStateProfilabilityByCandidate,
  action: memberActions
): ProfilabilityByCandidateState {
  switch (action.type) {
    case GET_PROFILABILITY_BY_CANDIDATE_SUCCESS: {
      return {
        ...state,
        profilabilityByCandidateList: action.payload,
      };
    }
    case GET_PROFILABILITY_BY_CANDIDATE_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}
export function selectionPlacementByCandidateReducer(
  state: SelectionPlacementByCandidateState = initialStateSelctionPlacementByCandidate,
  action: memberActions
): SelectionPlacementByCandidateState {
  switch (action.type) {
    case GET_SELECTION_PLACEMENT_BY_CANDIDATE_SUCCESS: {
      return {
        ...state,
        selectionPlacementByCandidateList: action.payload,
      };
    }
    case GET_SELECTION_PLACEMENT_BY_CANDIDATE_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}
export function candidateListReducer(
  state: CandidateListState = initialStateCandidateList,
  action: memberActions
): CandidateListState {
  switch (action.type) {
    case GET_CANDIDATE_LIST_SUCCESS: {
      return {
        ...state,
        candidateList: action.payload,
      };
    }
    case GET_CANDIDATE_LIST_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}
export function recruiterListReducer(
  state: RecruiterListState = initialStateRecruiterlist,
  action: memberActions
): RecruiterListState {
  switch (action.type) {
    case GET_RECRUITER_LIST_SUCCESS: {
      return {
        ...state,
        recruiterList: action.payload,
      };
    }
    case GET_RECRUITER_LIST_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function outsourcePersonnelListReducer(
  state: OutsourcePersonnelListState = initialStateOutsourcePersonnelList,
  action: memberActions
): OutsourcePersonnelListState {
  switch (action.type) {
    case GET_OUTSOURCE_PERSONNEL_LİST_SUCCESS: {
      return {
        ...state,
        outsourcePersonnel: action.payload,
      };
    }
    case GET_OUTSOURCE_PERSONNEL_LİST_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function companiesByMemberIdListReducer(
  state: CompaniesbyMemberIdListState = initialStateCompaniesbyMemberIdList,
  action: memberActions
): CompaniesbyMemberIdListState {
  switch (action.type) {
    case GET_COMPANIES_BY_MEMBER_ID_SUCCESS: {
      return {
        ...state,
        companiesbyMemberId: action.payload,
      };
    }
    case GET_COMPANIES_BY_MEMBER_ID_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function customerByProfitabilityReducer(
  state: CustomerByProfitabilityState = initialStateCustomerbyProfitability,
  action: memberActions
): CustomerByProfitabilityState {
  switch (action.type) {
    case GET_CUSTOMER_BY_PROFİTABİLİTY_SUCCESS: {
      return {
        ...state,
        customerbyProfitability: action.payload,
      };
    }
    case GET_CUSTOMER_BY_PROFİTABİLİTY_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function nettingReducer(
  state: NettingListState = initialStateNettingList,
  action: memberActions
): NettingListState {
  switch (action.type) {
    case GET_NETTİNG_LİST_SUCCESS: {
      return {
        ...state,
        netting: action.payload,
      };
    }
    case GET_CUSTOMER_BY_PROFİTABİLİTY_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function positionListReducer(
  state: PositionListState = initialStatePositionList,
  action: memberActions
): PositionListState {
  switch (action.type) {
    case GET_POSİTİON_LİST_SUCCESS: {
      return {
        ...state,
        positionList: action.payload,
      };
    }
    case GET_POSİTİON_LİST_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function salarySummaryListReducer(
  state: SalarySummaryListState = initialStateSalarySummaryList,
  action: memberActions
): SalarySummaryListState {
  switch (action.type) {
    case GET_SALARY_SUMMARY_LİST_SUCCESS: {
      return {
        ...state,
        salarySummaryList: action.payload,
      };
    }
    case GET_SALARY_SUMMARY_LİST_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function budgetbyMainCodeReducer(
  state: BudgetByMainCodeState = initialStateBudgetByMainCodeState,
  action: memberActions
): BudgetByMainCodeState {
  switch (action.type) {
    case GET_BUDGET_BY_MAIN_CODE_SUCCESS: {
      return {
        ...state,
        budgetByMainCode: action.payload,
      };
    }
    case GET_BUDGET_BY_MAIN_CODE_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function budgetBySubCodeReducer(
  state: BudgetBySubCodeState = initialStateBudgetBySubCodeState,
  action: memberActions
): BudgetBySubCodeState {
  switch (action.type) {
    case GET_BUDGET_BY_SUB_CODE_SUCCESS: {
      return {
        ...state,
        budgetBySubCode: action.payload,
      };
    }
    case GET_BUDGET_BY_SUB_CODE_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function budgetByCostCenterCodeReducer(
  state: BudgetByCostCenterCodeState = initialStateBudgetByCostCenterCodeState,
  action: memberActions
): BudgetByCostCenterCodeState {
  switch (action.type) {
    case GET_BUDGET_BY_COST_CENTER_CODE_SUCCESS: {
      return {
        ...state,
        budgetByCostCenterCode: action.payload,
      };
    }
    case GET_BUDGET_BY_COST_CENTER_CODE_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function budgetGeneralReportReducer(
  state: BudgetGeneralReportState = initialStateBudgetGeneralReportState,
  action: memberActions
): BudgetGeneralReportState {
  switch (action.type) {
    case GET_BUDGET_GENERAL_REPORT_SUCCESS: {
      return {
        ...state,
        budgetGeneralReport: action.payload,
      };
    }
    case GET_BUDGET_GENERAL_REPORT_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function budgetMainReportReducer(
  state: BudgetMainReportState = initialStateBudgetMainReportState,
  action: memberActions
): BudgetMainReportState {
  switch (action.type) {
    case GET_BUDGET_MAIN_REPORT_SUCCESS: {
      return {
        ...state,
        budgetMainReport: action.payload,
      };
    }
    case GET_BUDGET_MAIN_REPORT_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function budgetSubReportReducer(
  state: BudgetSubReportState = initialStateBudgetSubReportState,
  action: memberActions
): BudgetSubReportState {
  switch (action.type) {
    case GET_BUDGET_SUB_REPORT_SUCCESS: {
      return {
        ...state,
        budgetSubReport: action.payload,
      };
    }
    case GET_BUDGET_SUB_REPORT_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function budgetDetailReportReducer(
  state: BudgetDetailReportState = initialStateBudgetDetailReportState,
  action: memberActions
): BudgetDetailReportState {
  switch (action.type) {
    case GET_BUDGET_DETAIL_REPORT_SUCCESS: {
      return {
        ...state,
        budgetDetailReport: action.payload,
      };
    }
    case GET_BUDGET_DETAIL_REPORT_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function statusStatisticListReducer(
  state: StatusStatisticListState = initialStateStatusStatisticList,
  action: memberActions
): StatusStatisticListState {
  switch (action.type) {
    case GET_STATUS_STATİSTİC_LİST_SUCCESS: {
      return {
        ...state,
        statusStatisticList: action.payload,
      };
    }
    case GET_STATUS_STATİSTİC_LİST_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function exchangeBankRateListReducer(
  state: ExchangeBankRateListState = initialStateExchangeBankRateList,
  action: memberActions
): ExchangeBankRateListState {
  switch (action.type) {
    case GET_EXCHANGE_BANK_RATES_LİST_SUCCESS: {
      return {
        ...state,
        exchangeBankRateList: action.payload,
      };
    }
    case GET_EXCHANGE_BANK_RATES_LİST_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}


export function statusStatisticbyMemberListReducer(
  state: StatusStatisticbyMemberListState = initialStateStatusStatisticbyMemberList,
  action: memberActions
): StatusStatisticbyMemberListState {
  switch (action.type) {
    case GET_STATUS_STATİSTİC_BY_MEMBER_LİST_SUCCESS: {
      return {
        ...state,
        statusStatisticbyMemberList: action.payload,
      };
    }
    case GET_STATUS_STATİSTİC_BY_MEMBER_LİST_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function budgetForeCastbyMainCodeListReducer(
  state: BudgetForeCastbyMainCodeListState = initialStateBudgetForeCastbyMainCode,
  action: memberActions
): BudgetForeCastbyMainCodeListState {
  switch (action.type) {
    case GET_BUDGET_FORECAST_BY_MAINCODE_LİST_SUCCESS: {
      return {
        ...state,
        budgetForeCastbyMainCodeList: action.payload,
      };
    }
    case GET_BUDGET_FORECAST_BY_MAINCODE_LİST_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function budgetForeCastbySubCodeListReducer(
  state: BudgetForeCastbySubCodeListState = initialStateBudgetForeCastbySubCode,
  action: memberActions
): BudgetForeCastbySubCodeListState {
  switch (action.type) {
    case GET_BUDGET_FORECAST_BY_SUBCODE_LİST_SUCCESS: {
      return {
        ...state,
        budgetForeCastbySubCodeList: action.payload,
      };
    }
    case GET_BUDGET_FORECAST_BY_SUBCODE_LİST_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}

export function budgetForeCastbyCostCenterCodeListReducer(
  state: BudgetForeCastbyCostCenterCodeListState = initialStateBudgetForeCastbyCostCenterCode,
  action: memberActions
): BudgetForeCastbyCostCenterCodeListState {
  switch (action.type) {
    case GET_BUDGET_FORECAST_BY_COSTCENTERCODE_LİST_SUCCESS: {
      return {
        ...state,
        budgetForeCastbyCostCenterCodeList: action.payload,
      };
    }
    case GET_BUDGET_FORECAST_BY_COSTCENTERCODE_LİST_ERROR: {
      return {
        ...state,
        message: action.payload,
      };
    }
    default:
      return state;
  }
}
