export const GET_COST_CENTER_SUCCESS = "GET_COST_CENTER_SUCCSS";
export const GET_COST_CENTER_ERROR = "GET_COST_CENTER_ERROR";
export const GET_COMPANIES_SUCCESS = "GET_COMPANIES_SUCCESS";
export const GET_COMPANIES_ERROR = "GET_COMPANIES_ERROR";
export const GET_CURRENT_DEBT_LİST_SUCCESS = "GET_CURRENT_DEBT_LİST_SUCCESS";
export const GET_CURRENT_DEBT_LİST_ERROR = "GET_CURRENT_DEBT_LİST_ERROR";
export const GET_MY_USER_DETAIL_SUCCESS = "GET_MY_USER_DETAIL_SUCCESS";
export const GET_MY_USER_DETAIL_ERROR = "GET_MY_USER_DETAIL_ERROR";
export const GET_MAİN_COST_CENTER_GROUP_SUCCESS =
  "GET_MAİN_COST_CENTER_GROUP__SUCCESS";
export const GET_MAİN_COST_CENTER_GROUP_ERROR =
  "GET_MAİN_COST_CENTER_GROUP__ERROR";
export const GET_SUB_COST_CENTER_GROUP_SUCCESS =
  "GET_SUB_COST_CENTER_GROUP_SUCCESS";
export const GET_SUB_COST_CENTER_GROUP_ERROR =
  "GET_SUB_COST_CENTER_GROUP_ERROR";
export const GET_COST_CENTER_LİST_SUCCESS = "GET_COST_CENTER_LİST_SUCCESS";
export const GET_COST_CENTER_LİST_ERROR = "GET_COST_CENTER_LİST_ERROR";
export const GET_COST_CENTER_DETAIL_ERROR = "GET_COST_CENTER_DETAIL_ERROR";
export const GET_COST_CENTER_DETAIL_SUCCESS = "GET_COST_CENTER_DETAIL_SUCCESS";
export const GET_FİRM_NAMES_SUCCESS = "GET_FİRM_NAMES_SUCCESS";
export const GET_FİRM_NAMES_ERROR = "GET_FİRM_NAMES_ERROR";
export const GET_CUSTOMER_LİST_BY_COMPANYID_SUCCESS =
  "GET_CUSTOMER_LİST_BY_COMPANYID_SUCCESS";
export const GET_CUSTOMER_LİST_BY_COMPANYID_ERROR =
  "GET_CUSTOMER_LİST_BY_COMPANYID_ERROR";
export const GET_PERSONNEL_SALARY_LIST_SUCCESS =
  "GET_LIST_PERSONNEL_SALARY_SUCCESS";
export const GET_PERSONNEL_SALARY_LIST_ERROR =
  "GET_LIST_PERSONNEL_SALARY_ERROR";
export const GET_ALL_PERSONNEL_LİST_SUCCESS = "GET_ALL_MEMBER_LİST_SUCCESS";
export const GET_ALL_PERSONNEL_LİST_ERROR = "GET_ALL_MEMBER_LİST_ERROR";
export const GET_MAİN_COST_CENTER_LİST_SUCCESS =
  "GET_MAİN_COST_CENTER_LİST_SUCCESS";
export const GET_MAİN_COST_CENTER_LİST_ERROR =
  "GET_MAİN_COST_CENTER_LİST_ERROR";
export const GET_SUB_COST_CENTER_LİST_SUCCESS =
  "GET_SUB_COST_CENTER_LİST_SUCCESS";
export const GET_SUB_COST_CENTER_LİST_ERROR = "GET_SUB_COST_CENTER_LİST_ERROR";
export const GET_COST_CENTER_TREE_LİST_SUCCESS =
  "GET_COST_CENTER_TREE_LİST_SUCCESS";
export const GET_COST_CENTER_TREE_LİST_ERROR =
  "GET_COST_CENTER_TREE_LİST_ERROR";
export const GET_CUSTOMER_LİST_BY_INPUT_SUCCESS =
  "GET_CUSTOMER_LİST_BY_INPUT_SUCCESS";

export const GET_CUSTOMER_LİST_SUCCESS = "GET_CUSTOMER_LİST_SUCCESS";
export const GET_CUSTOMER_LİST_ERROR = "GET_CUSTOMER_LİST_ERROR";

export const GET_CUSTOMER_LİST_BY_INPUT_ERROR =
  "GET_CUSTOMER_LİST_BY_INPUT_ERROR";
export const GET_COMPANY_LİST_BY_INPUT_SUCCESS =
  "GET_COMPANY_LİST_BY_INPUT_SUCCESS";
export const GET_COMPANY_LİST_BY_INPUT_ERROR =
  "GET_COMPANY_LİST_BY_INPUT_ERROR";
export const GET_SALARY_DETAİL_LİST_SUCCESS = "GET_SALARY_DETAİL_LİST_SUCCESS";
export const GET_SALARY_DETAİL_LİST_ERROR = "GET_SALARY_DETAİL_LİST_ERROR";
export const GET_PERSONEL_LİST_BY_SUB_STATUS_SUCCESS =
  "GET_PERSONEL_LİST_BY_SUB_STATUS_SUCCESS";
export const GET_PERSONEL_LİST_BY_SUB_STATUS_ERROR =
  "GET_PERSONEL_LİST_BY_SUB_STATUS_ERROR";
export const GET_PERSONEL_LİST_BY_SALARY_DETAİL_SUCCESS =
  "GET_PERSONEL_LİST_BY_SALARY_DETAİL_SUCCESS";
export const GET_PERSONEL_LİST_BY_SALARY_DETAİL_ERROR =
  "GET_PERSONEL_LİST_BY_SALARY_DETAİL_ERROR";
export const GET_CURRENT_DEBT_REPORT_BY_MAİN_CODE_SUCCESS =
  "GET_CURRENT_DEBT_REPORT_BY_MAİN_CODE_SUCCESS";
export const GET_CURRENT_DEBT_REPORT_BY_MAİN_CODE_ERROR =
  "GET_CURRENT_DEBT_REPORT_BY_MAİN_CODE_ERROR";
export const GET_CURRENT_DEBT_REPORT_BY_SUB_CODE_SUCCESS =
  "GET_CURRENT_DEBT_REPORT_BY_SUB_CODE_SUCCESS";
export const GET_CURRENT_DEBT_REPORT_BY_SUB_CODE_ERROR =
  "GET_CURRENT_DEBT_REPORT_BY_SUB_CODE_ERROR";
export const GET_CURRENT_DEBT_REPORT_BY_COST_CENTER_CODE_SUCCESS =
  "GET_CURRENT_DEBT_REPORT_BY_COST_CENTER_CODE_SUCCESS";
export const GET_CURRENT_DEBT_REPORT_BY_COST_CENTER_CODE_ERROR =
  "GET_CURRENT_DEBT_REPORT_BY_COST_CENTER_CODE_ERROR";
export const GET_CURRENT_DEBT_REPORT_BY_DETAİL_SUCCESS =
  "GET_CURRENT_DEBT_REPORT_BY_DETAİL_SUCCESS";
export const GET_CURRENT_DEBT_REPORT_BY_DETAİL_ERROR =
  "GET_CURRENT_DEBT_REPORT_BY_DETAİL_ERROR";
export const GET_PERSONNEL_LİST_FOR_LAST_YEAR_SUCCESS =
  "GET_PERSONNEL_LİST_FOR_LAST_YEAR_SUCCESS";
export const GET_PERSONNEL_LİST_FOR_LAST_YEAR_ERROR =
  "GET_PERSONNEL_LİST_FOR_LAST_YEAR_ERROR";
export const GET_CURRENT_PERİOD_REPORT_BY_COMPANY_SUCCESS =
  "GET_CURRENT_PERİOD_REPORT_BY_COMPANY_SUCCESS";
export const GET_CURRENT_PERİOD_REPORT_BY_COMPANY_ERROR =
  "GET_CURRENT_PERİOD_REPORT_BY_COMPANY_ERROR";
export const GET_CURRENT_PERİOD_REPORT_BY_MONTH_SUCCESS =
  "GET_CURRENT_PERİOD_REPORT_BY_MONTH_SUCCESS";
export const GET_CURRENT_PERİOD_REPORT_BY_MONTH_ERROR =
  "GET_CURRENT_PERİOD_REPORT_BY_MONTH_ERROR";
export const GET_CURRENT_PERİOD_REPORT_BY_FİRM_SUCCESS =
  "GET_CURRENT_PERİOD_REPORT_BY_FİRM_SUCCESS";
export const GET_CURRENT_PERİOD_REPORT_BY_FİRM_ERROR =
  "GET_CURRENT_PERİOD_REPORT_BY_FİRM_ERROR";
export const GET_EMPLOYEES_BY_INPUT_SUCCESS = "GET_EMPLOYEES_BY_INPUT_SUCCESS";
export const GET_EMPLOYEES_BY_INPUT_ERROR = "GET_EMPLOYEES_BY_INPUT_ERROR";
export const GET_TECHNİC_GROUP_MAİN_LİST_SUCCESS =
  "GET_TECHNİC_GROUP_MAİN_LİST_SUCCESS";
export const GET_TECHNİC_GROUP_MAİN_LİST_ERROR =
  "GET_TECHNİC_GROUP_MAİN_LİST_ERROR";
export const GET_ALL_TECHNICAL_GROUP_SUCCESS =
  "GET_ALL_TECHNICAL_GROUP_SUCCESS";
export const GET_ALL_TECHNICAL_GROUP_ERROR = "GET_ALL_TECHNICAL_GROUP_ERROR";
export const GET_PROFILABILITY_BY_CANDIDATE_SUCCESS =
  "GET_PROFILABILITY_BY_CANDIDATE_SUCCESS";
export const GET_PROFILABILITY_BY_CANDIDATE_ERROR =
  "GET_PROFILABILITY_BY_CANDIDATE_ERROR";
export const GET_CANDIDATE_LIST_SUCCESS = "GET_CANDIDATE_LIST_SUCCESS";
export const GET_CANDIDATE_LIST_ERROR = "GET_CANDIDATE_LIST_ERROR";
export const GET_RECRUITER_LIST_SUCCESS = "GET_RECRUITER_LIST_SUCCESS";
export const GET_RECRUITER_LIST_ERROR = "GET_RECRUITER_LIST_ERROR";
export const GET_OUTSOURCE_PERSONNEL_LİST_SUCCESS =
  "GET_OUTSOURCE_PERSONNEL_LİST_SUCCESS";
export const GET_OUTSOURCE_PERSONNEL_LİST_ERROR =
  "GET_OUTSOURCE_PERSONNEL_LİST_ERROR";
export const GET_COMPANIES_BY_MEMBER_ID_SUCCESS =
  "GET_COMPANIES_BY_MEMBER_ID_SUCCESS";
export const GET_COMPANIES_BY_MEMBER_ID_ERROR =
  "GET_COMPANIES_BY_MEMBER_ID_ERROR";
export const GET_CUSTOMER_BY_PROFİTABİLİTY_SUCCESS =
  "GET_CUSTOMER_BY_PROFİTABİLİTY_SUCCESS";
export const GET_CUSTOMER_BY_PROFİTABİLİTY_ERROR =
  "GET_CUSTOMER_BY_PROFİTABİLİTY_ERROR";
export const GET_NETTİNG_LİST_SUCCESS = "GET_NETTİNG_LİST_SUCCESS";
export const GET_NETTİNG_LİST_ERROR = "GET_NETTİNG_LİST_ERROR";
export const GET_POSİTİON_LİST_SUCCESS = "GET_POSİTİON_LİST_SUCCESS";
export const GET_POSİTİON_LİST_ERROR = "GET_POSİTİON_LİST_ERROR";
export const GET_SELECTION_PLACEMENT_BY_CANDIDATE_SUCCESS =
  "GET_SELECTION_PLACEMENT_BY_CANDIDATE_SUCCESS";
export const GET_SELECTION_PLACEMENT_BY_CANDIDATE_ERROR =
  "GET_SELECTION_PLACEMENT_BY_CANDIDATE_ERROR";
export const GET_SALARY_SUMMARY_LİST_SUCCESS = "GET_SALARY_SUMMARY_SUCCESS";
export const GET_SALARY_SUMMARY_LİST_ERROR = "GET_SALARY_SUMMARY_ERROR";
export const GET_BUDGET_BY_MAIN_CODE_SUCCESS =
  "GET_BUDGET_BY_MAIN_CODE_SUCCESS";
export const GET_BUDGET_BY_MAIN_CODE_ERROR = "GET_BUDGET_BY_MAIN_CODE_ERROR";
export const GET_BUDGET_BY_SUB_CODE_SUCCESS = "GET_BUDGET_BY_SUB_CODE_SUCCESS";
export const GET_BUDGET_BY_SUB_CODE_ERROR = "GET_BUDGET_BY_SUB_CODE_ERROR";
export const GET_BUDGET_BY_COST_CENTER_CODE_SUCCESS =
  "GET_BUDGET_BY_COST_CENTER_CODE_SUCCESS";
export const GET_BUDGET_BY_COST_CENTER_CODE_ERROR =
  "GET_BUDGET_BY_COST_CENTER_CODE_ERROR";
export const GET_BUDGET_GENERAL_REPORT_SUCCESS =
  "GET_BUDGET_GENERAL_REPORT_SUCCESS";
export const GET_BUDGET_GENERAL_REPORT_ERROR =
  "GET_BUDGET_GENERAL_REPORT_ERROR";
export const GET_BUDGET_MAIN_REPORT_SUCCESS = "GET_BUDGET_MAIN_REPORT_SUCCESS";
export const GET_BUDGET_MAIN_REPORT_ERROR = "GET_BUDGET_MAIN_REPORT_ERROR";
export const GET_BUDGET_SUB_REPORT_SUCCESS = "GET_BUDGET_SUB_REPORT_SUCCESS";
export const GET_BUDGET_SUB_REPORT_ERROR = "GET_BUDGET_SUB_REPORT_ERROR";
export const GET_BUDGET_DETAIL_REPORT_SUCCESS =
  "GET_BUDGET_DETAIL_REPORT_SUCCESS";
export const GET_BUDGET_DETAIL_REPORT_ERROR = "GET_BUDGET_SUB_REPORT_ERROR";
export const GET_STATUS_STATİSTİC_LİST_SUCCESS =
  "GET_STATUS_STATİSTİC_LİST_SUCCESS";
export const GET_STATUS_STATİSTİC_LİST_ERROR =
  "GET_STATUS_STATİSTİC_LİST_ERROR";
  export const GET_EXCHANGE_BANK_RATES_LİST_SUCCESS =
  "GET_EXCHANGE_BANK_RATES_LİST_SUCCESS";
export const GET_EXCHANGE_BANK_RATES_LİST_ERROR =
  "GET_EXCHANGE_BANK_RATES_LİST_ERROR";
export const GET_STATUS_STATİSTİC_BY_MEMBER_LİST_SUCCESS =
  "GET_STATUS_STATİSTİC_BY_MEMBER_LİST_SUCCESS";
export const GET_STATUS_STATİSTİC_BY_MEMBER_LİST_ERROR =
  "GET_STATUS_STATİSTİC_BY_MEMBER_LİST_ERROR";

export const GET_BUDGET_FORECAST_BY_MAINCODE_LİST_SUCCESS =
  "GET_BUDGET_FORECAST_BY_MAINCODE_LİST_SUCCESS";
export const GET_BUDGET_FORECAST_BY_MAINCODE_LİST_ERROR =
  "GET_BUDGET_FORECAST_BY_MAINCODE_LİST_ERROR";

export const GET_BUDGET_FORECAST_BY_SUBCODE_LİST_SUCCESS =
  "GET_BUDGET_FORECAST_BY_SUBCODE_LİST_SUCCESS";
export const GET_BUDGET_FORECAST_BY_SUBCODE_LİST_ERROR =
  "GET_BUDGET_FORECAST_BY_SUBCODE_LİST_ERROR";

export const GET_BUDGET_FORECAST_BY_COSTCENTERCODE_LİST_SUCCESS =
  "GET_BUDGET_FORECAST_BY_COSTCENTERCODE_LİST_SUCCESS";
export const GET_BUDGET_FORECAST_BY_COSTCENTERCODE_LİST_ERROR =
  "GET_BUDGET_FORECAST_BY_COSTCENTERCODE_LİST_ERROR";

export interface Member {
  id: number;
  userName: string;
}
export interface UserDetail {
  id: number;
  userName: string;
}
export interface CostCenter {
  id: number;
}
export interface Companies {
  id: number;
}
export interface CurrentDebt {
  firmName: string;
  input: string;
  debtDate: Date;
  costCenterCode: number;
  companies: number;
}
export interface MainCostCenter {
  name: string;
}
export interface SubCostCenter {
  name: string;
}
export interface CostCenterList {
  companies: number;
  mainGroupName: string;
}
export interface CostCenterDetail {
  name: string;
}
export interface Firm {
  firmName: string;
}
export interface Customer {
  companyId: number;
}
export interface Salary {
  salaryId: number;
}
export interface AllPersonnel { }
export interface MainCostCenterList { }
export interface SubCostCenterList { }
export interface CostCenterTreeList { }
export interface CustomerbyInput { }
export interface CompanybyInput { }
export interface SalaryDetailList { }
export interface PersonelListBySubStatus { }
export interface PersonelListBySalaryDetail { }
export interface CurrentDebtReportbyMainCode { }
export interface CurrentDebtReportbySubCode { }
export interface CurrentDebtReportbyCostCenterCode { }
export interface CurrentDebtReportbyDetail { }
export interface PersonnelListForLastYear { }
export interface CurrentPeriodReportbyCompany { }
export interface CurrentPeriodReportbyMonth { }
export interface CurrentPeriodReportbyFirm { }
export interface EmployeesByInput { }
export interface SubTechnologiesListbyMainGroup { }
export interface TechnicGroupMainList { }
export interface TechnicalList { }
export interface ProfilabilityByCandidateList { }
export interface SelectionPlacementByCandidateList { }
export interface CandidateList { }
export interface RecruiterList { }

export interface OutsourcePersonnel { }

export interface CompaniesbyMemberId { }

export interface CustomerbyProfitability { }

export interface Netting { }

export interface PositionList { }

export interface SalarySummaryList { }

export interface BudgetByMainCode { }

export interface BudgetBySubCode { }

export interface BudgetByCostCenterCode { }

export interface BudgetGeneralReport { }

export interface BudgetMainReport { }

export interface BudgetSubReport { }

export interface BudgetDetailReport { }
export interface StatusStatisticList { }
export interface ExchangeBankRateList { }
export interface StatusStatisticbyMemberList { }
export interface BudgetForeCastbyMainCodeList { }
export interface BudgetForeCastbySubCodeList { }
export interface BudgetForeCastbyCostCenterCodeList { }





interface getStatusStatisticListSuccess {
  type: typeof GET_STATUS_STATİSTİC_LİST_SUCCESS;
  payload: StatusStatisticList[];
}

interface getStatusStatisticListError {
  type: typeof GET_STATUS_STATİSTİC_LİST_ERROR;
  payload: string;
}

interface getExchangeBankRatesListSuccess {
  type: typeof GET_EXCHANGE_BANK_RATES_LİST_SUCCESS;
  payload: ExchangeBankRateList[];
}

interface getExchangeBankRatesListError {
  type: typeof GET_EXCHANGE_BANK_RATES_LİST_ERROR;
  payload: string;
}

interface getStatusStatisticbyMemberListSuccess {
  type: typeof GET_STATUS_STATİSTİC_BY_MEMBER_LİST_SUCCESS;
  payload: StatusStatisticbyMemberList[];
}

interface getStatusStatisticbyMemberListError {
  type: typeof GET_STATUS_STATİSTİC_BY_MEMBER_LİST_ERROR;
  payload: string;
}
interface getComponiesSuccess {
  type: typeof GET_COMPANIES_SUCCESS;
  payload: Companies[];
}

interface getComponiesError {
  type: typeof GET_COMPANIES_ERROR;
  payload: string;
}

interface getCostCenterListSuccess {
  type: typeof GET_COST_CENTER_SUCCESS;
  payload: CostCenter[];
}

interface getCostCenterListError {
  type: typeof GET_COST_CENTER_ERROR;
  payload: string;
}

interface getCurrentDebtListSuccess {
  type: typeof GET_CURRENT_DEBT_LİST_SUCCESS;
  payload: CurrentDebt[];
}
interface getCurrentDebtListError {
  type: typeof GET_CURRENT_DEBT_LİST_ERROR;
  payload: string;
}
interface getMyUserDetailListSuccess {
  type: typeof GET_MY_USER_DETAIL_SUCCESS;
  payload: UserDetail[];
}

interface getMyUserDetailListError {
  type: typeof GET_MY_USER_DETAIL_ERROR;
  payload: string;
}
interface getMainCostCenterGroupSuccess {
  type: typeof GET_MAİN_COST_CENTER_GROUP_SUCCESS;
  payload: MainCostCenter[];
}

interface getMainCostCenterGroupError {
  type: typeof GET_MAİN_COST_CENTER_GROUP_ERROR;
  payload: string;
}
interface getSubCostCenterGroupSuccess {
  type: typeof GET_SUB_COST_CENTER_GROUP_SUCCESS;
  payload: SubCostCenter[];
}

interface getSubCostCenterGroupError {
  type: typeof GET_SUB_COST_CENTER_GROUP_ERROR;
  payload: string;
}
interface getAllCostCenterListSuccess {
  type: typeof GET_COST_CENTER_LİST_SUCCESS;
  payload: CostCenterList[];
}

interface getAllCostCenterListError {
  type: typeof GET_COST_CENTER_LİST_ERROR;
  payload: string;
}

interface getCostCenterDetailSuccess {
  type: typeof GET_COST_CENTER_DETAIL_SUCCESS;
  payload: CostCenterDetail[];
}

interface getCostCenterDetailError {
  type: typeof GET_COST_CENTER_DETAIL_ERROR;
  payload: string;
}
interface getFirmNamesSuccess {
  type: typeof GET_FİRM_NAMES_SUCCESS;
  payload: Firm[];
}

interface getFirmNamesError {
  type: typeof GET_FİRM_NAMES_ERROR;
  payload: string;
}
interface getCustomerListByCompanyIdSuccess {
  type: typeof GET_CUSTOMER_LİST_BY_COMPANYID_SUCCESS;
  payload: Customer[];
}

interface getCustomerListByCompanyIdError {
  type: typeof GET_CUSTOMER_LİST_BY_COMPANYID_ERROR;
  payload: string;
}
interface getPersonnelSalaryListSuccess {
  type: typeof GET_PERSONNEL_SALARY_LIST_SUCCESS;
  payload: Salary[];
}
interface getPersonnelSalaryListError {
  type: typeof GET_PERSONNEL_SALARY_LIST_ERROR;
  payload: string;
}
interface getAllPersonnelListSuccess {
  type: typeof GET_ALL_PERSONNEL_LİST_SUCCESS;
  payload: AllPersonnel[];
}

interface getAllPersonnelListError {
  type: typeof GET_ALL_PERSONNEL_LİST_ERROR;
  payload: string;
}

interface getMainCostCenterListSuccess {
  type: typeof GET_MAİN_COST_CENTER_LİST_SUCCESS;
  payload: MainCostCenterList[];
}

interface getMainCostCenterListError {
  type: typeof GET_MAİN_COST_CENTER_LİST_ERROR;
  payload: string;
}

interface getSubCostCenterListSuccess {
  type: typeof GET_SUB_COST_CENTER_LİST_SUCCESS;
  payload: SubCostCenterList[];
}

interface getSubCostCenterListError {
  type: typeof GET_SUB_COST_CENTER_LİST_ERROR;
  payload: string;
}

interface getCostCenterTreeListSuccess {
  type: typeof GET_COST_CENTER_TREE_LİST_SUCCESS;
  payload: SubCostCenterList[];
}

interface getCostCenterTreeListError {
  type: typeof GET_COST_CENTER_TREE_LİST_ERROR;
  payload: string;
}
interface getCustomerbyInputListSuccess {
  type: typeof GET_CUSTOMER_LİST_BY_INPUT_SUCCESS;
  payload: CustomerbyInput[];
}

interface getCustomerbyInputListError {
  type: typeof GET_CUSTOMER_LİST_BY_INPUT_ERROR;
  payload: string;
}
interface getCompanybyInputListSuccess {
  type: typeof GET_COMPANY_LİST_BY_INPUT_SUCCESS;
  payload: CompanybyInput[];
}

interface getCompanybyInputListError {
  type: typeof GET_COMPANY_LİST_BY_INPUT_ERROR;
  payload: string;
}
interface getSalaryDetailListSuccess {
  type: typeof GET_SALARY_DETAİL_LİST_SUCCESS;
  payload: SalaryDetailList[];
}

interface getSalaryDetailListError {
  type: typeof GET_SALARY_DETAİL_LİST_ERROR;
  payload: string;
}

interface getPersonelListBySubStatusSuccess {
  type: typeof GET_PERSONEL_LİST_BY_SUB_STATUS_SUCCESS;
  payload: PersonelListBySubStatus[];
}

interface getPersonelListBySubStatusError {
  type: typeof GET_PERSONEL_LİST_BY_SUB_STATUS_ERROR;
  payload: string;
}

interface getPersonelListBySalaryDetailSuccess {
  type: typeof GET_PERSONEL_LİST_BY_SALARY_DETAİL_SUCCESS;
  payload: PersonelListBySubStatus[];
}

interface getPersonelListBySalaryDetailError {
  type: typeof GET_PERSONEL_LİST_BY_SALARY_DETAİL_ERROR;
  payload: string;
}

interface getCurrentDebtReportbyMainCodeSuccess {
  type: typeof GET_CURRENT_DEBT_REPORT_BY_MAİN_CODE_SUCCESS;
  payload: CurrentDebtReportbyMainCode[];
}

interface getCurrentDebtReportbyMainCodeError {
  type: typeof GET_CURRENT_DEBT_REPORT_BY_MAİN_CODE_ERROR;
  payload: string;
}

interface getCurrentDebtReportbySubCodeSuccess {
  type: typeof GET_CURRENT_DEBT_REPORT_BY_SUB_CODE_SUCCESS;
  payload: CurrentDebtReportbySubCode[];
}

interface getCurrentDebtReportbySubCodeError {
  type: typeof GET_CURRENT_DEBT_REPORT_BY_SUB_CODE_ERROR;
  payload: string;
}

interface getCurrentDebtReportbyCostCenterCodeSuccess {
  type: typeof GET_CURRENT_DEBT_REPORT_BY_COST_CENTER_CODE_SUCCESS;
  payload: CurrentDebtReportbySubCode[];
}

interface getCurrentDebtReportbyCostCenterCodeError {
  type: typeof GET_CURRENT_DEBT_REPORT_BY_COST_CENTER_CODE_ERROR;
  payload: string;
}

interface getCurrentDebtReportbyDetailSuccess {
  type: typeof GET_CURRENT_DEBT_REPORT_BY_DETAİL_SUCCESS;
  payload: CurrentDebtReportbySubCode[];
}

interface getCurrentDebtReportbyDetailError {
  type: typeof GET_CURRENT_DEBT_REPORT_BY_DETAİL_ERROR;
  payload: string;
}
interface getPersonnelListForLastYearSuccess {
  type: typeof GET_PERSONNEL_LİST_FOR_LAST_YEAR_SUCCESS;
  payload: PersonnelListForLastYear[];
}

interface getPersonnelListForLastYearError {
  type: typeof GET_PERSONNEL_LİST_FOR_LAST_YEAR_ERROR;
  payload: string;
}

interface getCurrentPeriodReportbyCompanySuccess {
  type: typeof GET_CURRENT_PERİOD_REPORT_BY_COMPANY_SUCCESS;
  payload: CurrentPeriodReportbyCompany[];
}

interface getCurrentPeriodReportbyCompanyError {
  type: typeof GET_CURRENT_PERİOD_REPORT_BY_COMPANY_ERROR;
  payload: string;
}

interface getCurrentPeriodReportbyMonthSuccess {
  type: typeof GET_CURRENT_PERİOD_REPORT_BY_MONTH_SUCCESS;
  payload: CurrentPeriodReportbyMonth[];
}

interface getCurrentPeriodReportbyMonthError {
  type: typeof GET_CURRENT_PERİOD_REPORT_BY_MONTH_ERROR;
  payload: string;
}
interface getCurrentPeriodReportbyFirmSuccess {
  type: typeof GET_CURRENT_PERİOD_REPORT_BY_FİRM_SUCCESS;
  payload: CurrentPeriodReportbyFirm[];
}
interface getCurrentPeriodReportbyFirmError {
  type: typeof GET_CURRENT_PERİOD_REPORT_BY_FİRM_ERROR;
  payload: string;
}
interface getEmployeesByInputSuccess {
  type: typeof GET_EMPLOYEES_BY_INPUT_SUCCESS;
  payload: EmployeesByInput[];
}
interface getEmployeesByInputError {
  type: typeof GET_EMPLOYEES_BY_INPUT_ERROR;
  payload: string;
}
interface getTechnicGroupMainListSuccess {
  type: typeof GET_TECHNİC_GROUP_MAİN_LİST_SUCCESS;
  payload: SubTechnologiesListbyMainGroup[];
}

interface getTechnicGroupMainListError {
  type: typeof GET_TECHNİC_GROUP_MAİN_LİST_ERROR;
  payload: string;
}
interface getTechnicalGroupListSuccess {
  type: typeof GET_ALL_TECHNICAL_GROUP_SUCCESS;
  payload: TechnicalList[];
}

interface getTechnicalGroupListError {
  type: typeof GET_ALL_TECHNICAL_GROUP_ERROR;
  payload: string;
}
interface getProfilabilityByCandidateListSuccess {
  type: typeof GET_PROFILABILITY_BY_CANDIDATE_SUCCESS;
  payload: ProfilabilityByCandidateList[];
}

interface getProfilabilityByCandidateListError {
  type: typeof GET_PROFILABILITY_BY_CANDIDATE_ERROR;
  payload: string;
}
interface getSelectionPlacementByCandidateListSuccess {
  type: typeof GET_SELECTION_PLACEMENT_BY_CANDIDATE_SUCCESS;
  payload: SelectionPlacementByCandidateList[];
}

interface getSelectionPlacementByCandidateListError {
  type: typeof GET_SELECTION_PLACEMENT_BY_CANDIDATE_ERROR;
  payload: string;
}
interface getCandidateListSuccess {
  type: typeof GET_CANDIDATE_LIST_SUCCESS;
  payload: ProfilabilityByCandidateList[];
}

interface getCandidateListError {
  type: typeof GET_CANDIDATE_LIST_ERROR;
  payload: string;
}
interface getRecruiterListSuccess {
  type: typeof GET_RECRUITER_LIST_SUCCESS;
  payload: RecruiterList[];
}

interface getRecruiterListError {
  type: typeof GET_RECRUITER_LIST_ERROR;
  payload: string;
}
interface getOutsourcePersonnelListSuccess {
  type: typeof GET_OUTSOURCE_PERSONNEL_LİST_SUCCESS;
  payload: OutsourcePersonnel[];
}

interface getOutsourcePersonnelListError {
  type: typeof GET_OUTSOURCE_PERSONNEL_LİST_ERROR;
  payload: string;
}

interface getCompaniesbyMemberIdListSuccess {
  type: typeof GET_COMPANIES_BY_MEMBER_ID_SUCCESS;
  payload: CompaniesbyMemberId[];
}

interface getCompaniesbyMemberIdListError {
  type: typeof GET_COMPANIES_BY_MEMBER_ID_ERROR;
  payload: string;
}

interface getCustomerByProfitabilitySuccess {
  type: typeof GET_CUSTOMER_BY_PROFİTABİLİTY_SUCCESS;
  payload: CustomerbyProfitability[];
}

interface getCustomerByProfitabilityError {
  type: typeof GET_CUSTOMER_BY_PROFİTABİLİTY_ERROR;
  payload: string;
}

interface getNettingListSuccess {
  type: typeof GET_NETTİNG_LİST_SUCCESS;
  payload: Netting[];
}

interface getNettingListError {
  type: typeof GET_NETTİNG_LİST_ERROR;
  payload: string;
}

interface getPositionListSuccess {
  type: typeof GET_POSİTİON_LİST_SUCCESS;
  payload: PositionList[];
}

interface getPositionListError {
  type: typeof GET_POSİTİON_LİST_ERROR;
  payload: string;
}

interface getSalarySummaryListSuccess {
  type: typeof GET_SALARY_SUMMARY_LİST_SUCCESS;
  payload: SalarySummaryList[];
}

interface getSalarySummaryListError {
  type: typeof GET_SALARY_SUMMARY_LİST_ERROR;
  payload: string;
}

interface getBudgetByMainCodeSuccess {
  type: typeof GET_BUDGET_BY_MAIN_CODE_SUCCESS;
  payload: BudgetByMainCode[];
}

interface getBudgetByMainCodeError {
  type: typeof GET_BUDGET_BY_MAIN_CODE_ERROR;
  payload: string;
}

interface getBudgetBySubCodeSuccess {
  type: typeof GET_BUDGET_BY_SUB_CODE_SUCCESS;
  payload: BudgetBySubCode[];
}

interface getBudgetBySubCodeError {
  type: typeof GET_BUDGET_BY_SUB_CODE_ERROR;
  payload: string;
}

interface getBudgetByCostCenterCodeSuccess {
  type: typeof GET_BUDGET_BY_COST_CENTER_CODE_SUCCESS;
  payload: BudgetByCostCenterCode[];
}

interface getBudgetByCostCenterCodeError {
  type: typeof GET_BUDGET_BY_COST_CENTER_CODE_ERROR;
  payload: string;
}

interface getBudgetGeneralReportSuccess {
  type: typeof GET_BUDGET_GENERAL_REPORT_SUCCESS;
  payload: BudgetGeneralReport[];
}

interface getBudgetGeneralReportError {
  type: typeof GET_BUDGET_GENERAL_REPORT_ERROR;
  payload: string;
}

interface getBudgetMainReportSuccess {
  type: typeof GET_BUDGET_MAIN_REPORT_SUCCESS;
  payload: BudgetMainReport[];
}

interface getBudgetMainReportError {
  type: typeof GET_BUDGET_MAIN_REPORT_ERROR;
  payload: string;
}

interface getBudgetSubReportSuccess {
  type: typeof GET_BUDGET_SUB_REPORT_SUCCESS;
  payload: BudgetSubReport[];
}

interface getBudgetSubReportError {
  type: typeof GET_BUDGET_SUB_REPORT_ERROR;
  payload: string;
}

interface getBudgetDetailbReportSuccess {
  type: typeof GET_BUDGET_DETAIL_REPORT_SUCCESS;
  payload: BudgetSubReport[];
}

interface getBudgetDetailReportError {
  type: typeof GET_BUDGET_DETAIL_REPORT_ERROR;
  payload: string;
}

interface getBudgetForecastListByMainCodeListSuccess {
  type: typeof GET_BUDGET_FORECAST_BY_MAINCODE_LİST_SUCCESS;
  payload: BudgetForeCastbyMainCodeList[];
}

interface getBudgetForecastListByMainCodeListError {
  type: typeof GET_BUDGET_FORECAST_BY_MAINCODE_LİST_ERROR;
  payload: string;
}

interface getBudgetForecastListBySubCodeListSuccess {
  type: typeof GET_BUDGET_FORECAST_BY_SUBCODE_LİST_SUCCESS;
  payload: BudgetForeCastbySubCodeList[];
}

interface getBudgetForecastListBySubCodeListError {
  type: typeof GET_BUDGET_FORECAST_BY_SUBCODE_LİST_ERROR;
  payload: string;
}

interface getBudgetForecastListByCostCenterCodeListSuccess {
  type: typeof GET_BUDGET_FORECAST_BY_COSTCENTERCODE_LİST_SUCCESS;
  payload: BudgetForeCastbyCostCenterCodeList[];
}

interface getBudgetForecastListByCostCenterCodeListError {
  type: typeof GET_BUDGET_FORECAST_BY_COSTCENTERCODE_LİST_ERROR;
  payload: string;
}

export type memberActions =
  | getBudgetDetailReportError
  | getBudgetDetailbReportSuccess
  | getBudgetSubReportError
  | getBudgetSubReportSuccess
  | getBudgetMainReportSuccess
  | getBudgetMainReportError
  | getBudgetGeneralReportError
  | getBudgetGeneralReportSuccess
  | getBudgetByMainCodeSuccess
  | getBudgetByMainCodeError
  | getBudgetByCostCenterCodeError
  | getBudgetByCostCenterCodeSuccess
  | getBudgetBySubCodeError
  | getBudgetBySubCodeSuccess
  | getCostCenterListSuccess
  | getCostCenterListError
  | getComponiesSuccess
  | getComponiesError
  | getCurrentDebtListSuccess
  | getCurrentDebtListError
  | getMyUserDetailListSuccess
  | getMyUserDetailListError
  | getMainCostCenterGroupSuccess
  | getMainCostCenterGroupError
  | getSubCostCenterGroupSuccess
  | getSubCostCenterGroupError
  | getAllCostCenterListSuccess
  | getAllCostCenterListError
  | getCostCenterDetailSuccess
  | getCostCenterDetailError
  | getFirmNamesError
  | getFirmNamesSuccess
  | getCustomerListByCompanyIdSuccess
  | getCustomerListByCompanyIdError
  | getPersonnelSalaryListSuccess
  | getPersonnelSalaryListError
  | getAllPersonnelListSuccess
  | getAllPersonnelListError
  | getMainCostCenterListSuccess
  | getMainCostCenterListError
  | getSubCostCenterListSuccess
  | getSubCostCenterListError
  | getCostCenterTreeListSuccess
  | getCostCenterTreeListError
  | getCompanybyInputListSuccess
  | getCustomerbyInputListSuccess
  | getCompanybyInputListError
  | getCustomerbyInputListError
  | getSalaryDetailListSuccess
  | getSalaryDetailListError
  | getPersonelListBySubStatusSuccess
  | getPersonelListBySubStatusError
  | getPersonelListBySalaryDetailSuccess
  | getPersonelListBySalaryDetailError
  | getCurrentDebtReportbyMainCodeSuccess
  | getCurrentDebtReportbyMainCodeError
  | getCurrentDebtReportbySubCodeSuccess
  | getCurrentDebtReportbySubCodeError
  | getCurrentDebtReportbyCostCenterCodeSuccess
  | getCurrentDebtReportbyCostCenterCodeError
  | getCurrentDebtReportbyDetailSuccess
  | getCurrentDebtReportbyDetailError
  | getPersonnelListForLastYearSuccess
  | getPersonnelListForLastYearError
  | getCurrentPeriodReportbyCompanySuccess
  | getCurrentPeriodReportbyCompanyError
  | getCurrentPeriodReportbyMonthSuccess
  | getCurrentPeriodReportbyMonthError
  | getCurrentPeriodReportbyFirmSuccess
  | getCurrentPeriodReportbyFirmError
  | getEmployeesByInputSuccess
  | getEmployeesByInputError
  | getTechnicGroupMainListSuccess
  | getTechnicGroupMainListError
  | getTechnicalGroupListSuccess
  | getTechnicalGroupListError
  | getProfilabilityByCandidateListSuccess
  | getProfilabilityByCandidateListError
  | getSelectionPlacementByCandidateListSuccess
  | getSelectionPlacementByCandidateListError
  | getCandidateListSuccess
  | getCandidateListError
  | getRecruiterListSuccess
  | getRecruiterListError
  | getOutsourcePersonnelListSuccess
  | getOutsourcePersonnelListError
  | getCompaniesbyMemberIdListSuccess
  | getCompaniesbyMemberIdListError
  | getCustomerByProfitabilitySuccess
  | getCustomerByProfitabilityError
  | getNettingListSuccess
  | getNettingListError
  | getPositionListSuccess
  | getPositionListError
  | getSalarySummaryListSuccess
  | getSalarySummaryListError
  | getStatusStatisticListError
  | getExchangeBankRatesListSuccess
  | getExchangeBankRatesListError
  | getStatusStatisticListSuccess
  | getStatusStatisticbyMemberListSuccess
  | getStatusStatisticbyMemberListError
  | getBudgetForecastListByMainCodeListSuccess
  | getBudgetForecastListByMainCodeListError
  | getBudgetForecastListBySubCodeListSuccess
  | getBudgetForecastListBySubCodeListError
  | getBudgetForecastListByCostCenterCodeListSuccess
  | getBudgetForecastListByCostCenterCodeListError;;
