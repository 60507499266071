import { combineReducers } from "redux";
import {
  costCenterReducer,
  companiesReducer,
  currentDebtReducer,
  myUserDetailReducer,
  mainCostCenterReducer,
  subCostCenterReducer,
  allCostCenterReducer,
  costCenterDetailReducer,
  firmReducer,
  customerReducer,
  salaryListReducer,
  allPersonnelReducer,
  mainCostCenterListReducer,
  subCostCenterListReducer,
  costCenterListReducer,
  companybyInputReducer,
  customerbyInputReducer,
  salaryDetailListReducer,
  personelListBySubStatusReducer,
  personelListBySalaryDetailReducer,
  currentDebtReportbyMainCodeReducer,
  currentDebtReportbySubCodeReducer,
  currentDebtReportbyCostCenterCodeReducer,
  currentDebtReportbyDetailReducer,
  personelListForLastYearReducer,
  currentPeriodReportbyCompanyReducer,
  currentPeriodReportbyFirmReducer,
  currentPeriodReportbyMonthReducer,
  employeesByInputReducer,
  technicGroupMainListReducer,
  technicalReducer,
  profilabilityByCandidateReducer,
  selectionPlacementByCandidateReducer,
  candidateListReducer,
  recruiterListReducer,
  outsourcePersonnelListReducer,
  companiesByMemberIdListReducer,
  customerByProfitabilityReducer,
  nettingReducer,
  positionListReducer,
  salarySummaryListReducer,
  budgetbyMainCodeReducer,
  budgetBySubCodeReducer,
  budgetByCostCenterCodeReducer,
  budgetGeneralReportReducer,
  budgetMainReportReducer,
  budgetSubReportReducer,
  budgetDetailReportReducer,
  statusStatisticListReducer,
  exchangeBankRateListReducer,
  statusStatisticbyMemberListReducer,
  budgetForeCastbyMainCodeListReducer,
  budgetForeCastbySubCodeListReducer,
  budgetForeCastbyCostCenterCodeListReducer


} from "./memberList";
import uiReducer from "../slices/ui";
//import cartReducer from "./memberList";

const rootReducer = combineReducers({
  ui: uiReducer,
  costCenter: costCenterReducer,
  companies: companiesReducer,
  currentDebt: currentDebtReducer,
  myUserDetail: myUserDetailReducer,
  mainCostCenter: mainCostCenterReducer,
  subCostCenter: subCostCenterReducer,
  allCostCenter: allCostCenterReducer,
  costCenterDetail: costCenterDetailReducer,
  firm: firmReducer,
  customer: customerReducer,
  salaryList: salaryListReducer,
  allPersonnel: allPersonnelReducer,
  mainCostCenterList: mainCostCenterListReducer,
  subCostCenterList: subCostCenterListReducer,
  costCenterList: costCenterListReducer,
  companybyInput: companybyInputReducer,
  customerbyInput: customerbyInputReducer,
  salaryDetailList: salaryDetailListReducer,
  personelListBySubStatus: personelListBySubStatusReducer,
  personelListBySalaryDetail: personelListBySalaryDetailReducer,
  currentDebtReportbyMainCode: currentDebtReportbyMainCodeReducer,
  currentDebtReportbySubCode: currentDebtReportbySubCodeReducer,
  currentDebtReportbyCostCenterCode: currentDebtReportbyCostCenterCodeReducer,
  currentDebtReportbyDetail: currentDebtReportbyDetailReducer,
  personelListForLastYear: personelListForLastYearReducer,
  currentPeriodReportbyCompany: currentPeriodReportbyCompanyReducer,
  currentPeriodReportbyMonth: currentPeriodReportbyMonthReducer,
  currentPeriodReportbyFirm: currentPeriodReportbyFirmReducer,
  employeesByInput: employeesByInputReducer,
  technicGroupMainList: technicGroupMainListReducer,
  technicalList: technicalReducer,
  profilabilityByCandidateList: profilabilityByCandidateReducer,
  selectionPlacementByCandidateList: selectionPlacementByCandidateReducer,
  candidateList: candidateListReducer,
  recruiterList: recruiterListReducer,
  outsourcePersonnelList: outsourcePersonnelListReducer,
  companiesbyMemberIdList: companiesByMemberIdListReducer,
  customerByProfitability: customerByProfitabilityReducer,
  netting: nettingReducer,
  positionList: positionListReducer,
  salarySummaryList: salarySummaryListReducer,
  budgetbyMainCode: budgetbyMainCodeReducer,
  budgetBySubCode: budgetBySubCodeReducer,
  budgetByCostCenterCode: budgetByCostCenterCodeReducer,
  budgetGeneralReport: budgetGeneralReportReducer,
  budgetMainReport: budgetMainReportReducer,
  budgetSubReport: budgetSubReportReducer,
  budgetDetailReport: budgetDetailReportReducer,
  statusStatisticList: statusStatisticListReducer,
  exchangeBankRateList:exchangeBankRateListReducer,
  statusStatisticbyMemberList: statusStatisticbyMemberListReducer,
  budgetForeCastbyMainCodeList: budgetForeCastbyMainCodeListReducer,
  budgetForeCastbySubCodeList: budgetForeCastbySubCodeListReducer,
  budgetForeCastbyCostCenterCodeList: budgetForeCastbyCostCenterCodeListReducer,


});

export default rootReducer;
