import styled, {
} from "../../shared/styled";

export const StyleComponent = styled.div`
    background-color: #8fcaca;
    width: 100%;
    height: auto;
    position: absolute;
`;
export const StyleSquare = styled.div`
    width: 85%;
    height: auto;
    background-color: #f5f5f5  ;
    position: static;
    margin: 110px auto 40px auto;
    border : 3px solid #3b8c8c;
    border-radius: 15px;
`;
export const StyleFormGroup = styled.div`
    padding: 10px 40px 10px 40px;
`;
export const StyleHeader = styled.div`
    position: relative;
    margin-left: 20px;
    font-size: 18px;
    color: #006464;
    font-family: -apple-system, BlinkMacSystemFont, "Inter UI", Roboto, sans-serif;
    padding: 20px;
`;