import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import  configuretore  from "./redux/reducers/configureStore";
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import ThemeProvider from "./theme-provider";

const store = configuretore(); 

ReactDOM.render(
  // <React.StrictMode>
  <Provider store={store}>
    <ThemeProvider>
      <App />
      </ThemeProvider>
</Provider>,
// </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
serviceWorker.unregister();
