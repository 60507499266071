import React from "react";
import { SpaceProps, BorderProps } from "../../shared/styled";
import { Container } from "../../../src/components/src/grid";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { StyledContent } from "./style";

interface IProps extends SpaceProps, BorderProps {
    className?: string;
    fullHeight?: boolean;
    align?: "top" | "center" | "bootom";
}

const Content: React.FC<IProps> = ({
    children,
    className,
    fullHeight,
    align,
    ...restProps
}) => {
    return (
        <StyledContent
            $fullHeight={fullHeight}
            $align={align}
            className={className}
            {...restProps}
        >
            <div>
                {children}
            </div>
        </StyledContent>
    );
};

export default Content;
