import * as React from 'react';
import { Button, Container, Row, Col } from '../../components';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  StyleComponent,
  StyleFormGroup,
  StyleHeader,
  StyleSquare,
} from './style';
import { FormGroup } from '../../components/src/forms/form-elements';
import { TextField } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import {X, Save} from 'react-feather';

const useStylesName = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      width: '100%',
      color: 'black',
      '& label.Mui-focused': {
        color: '#3b8c8c',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: '#3b8c8c',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'black',
        },
        '&:hover fieldset': {
          borderColor: 'orange',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#3b8c8c',
        },
      },
    },
  })
);

const useStylesDate = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      '& label.Mui-focused': {
        color: '#3b8c8c',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: '#3b8c8c',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'black',
        },
        '&:hover fieldset': {
          borderColor: 'orange',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#3b8c8c',
        },
      },
    },
  })
);
const useStylesSelect = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      width: '100%',
      color: 'black',
      '& label.Mui-focused': {
        color: '#3b8c8c',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: '#3b8c8c',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'black',
        },
        '&:hover fieldset': {
          borderColor: 'orange',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#3b8c8c',
        },
      },
    },
  })
);
const useStylesButton = makeStyles((theme: Theme) => 
    createStyles({
      buttonClear: {
        backgroundColor: "#fff",
        color: "#37404a",
        margin: "7px",
        border: "1px solid #37404a",
      },
      buttonSave: {
        backgroundColor: "#fff",
        color: "#37404a",
        margin: "7px",
        border: "1px solid #37404a",
        position: "relative",
        float: "right",
      },
      buttonIcon: {
        marginRight: "5px",
      }
    }),
);

const PersonnelRegistration: React.FC = () => {
  const classesName = useStylesName();
  const classesDate = useStylesDate();
  const classesSelect = useStylesSelect();
  const classesButton = useStylesButton();

  return (
    <StyleComponent>
      <Container>
        <StyleSquare>
          <StyleHeader>PERSONEL / YENİ ADAY GİRİŞİ</StyleHeader>
          <StyleFormGroup>
            <FormGroup>
              <Row>
                <Col lg md sm={11} xs={11} mb={10}>
                  <TextField
                    id="outlined-basic"
                    label="Adı"
                    variant="outlined"
                    className={classesName.formControl}
                  />
                </Col>
                <Col lg md sm={11} xs={11} mb={10}>
                  <TextField
                    id="outlined-basic"
                    label="Soyadı"
                    variant="outlined"
                    className={classesName.formControl}
                  />
                </Col>
                <Col lg={4} md={4} sm={11} xs={11} mb={10}>
                  <TextField
                    id="outlined-basic"
                    name="finishdate"
                    label="Görüşme Tarihi"
                    type="date"
                    className={classesDate.formControl}
                    fullWidth
                    inputProps={{
                      max: new Date().toISOString().slice(0, 10),
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg md sm={11} xs={11} mb={10}>
                  <FormControl
                    variant="outlined"
                    className={classesSelect.formControl}
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Cinsiyet
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      label="Cinsiyet"
                    >
                      <MenuItem value={10}>PARTNER'A YÖNLENDİRİLDİ</MenuItem>
                    </Select>
                  </FormControl>
                </Col>
                <Col lg md sm={11} xs={11} mb={10}>
                  <FormControl
                    variant="outlined"
                    className={classesSelect.formControl}
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Askerlik
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      label="Askerlik"
                    >
                      <MenuItem value={10}>PARTNER'A YÖNLENDİRİLDİ</MenuItem>
                    </Select>
                  </FormControl>
                </Col>
                <Col lg={4} md={4} sm={11} xs={11} mb={10}>
                  <TextField
                    id="outlined-basic"
                    name="finishdate"
                    label="Tecil Tarihi"
                    type="date"
                    className={classesDate.formControl}
                    fullWidth
                    inputProps={{
                      max: new Date().toISOString().slice(0, 10),
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Col>
              </Row>
              <Row>
                  <Col lg md sm={11} xs={11} mb={10}>          
                  <TextField
                    id="outlined-basic"
                    label="Web Adresi"
                    variant="outlined"
                    className={classesName.formControl}
                  /></Col>
              </Row>
              <Row>
                  <Col lg md sm={11} xs={11} mb={10}>          
                  <TextField
                    id="outlined-basic"
                    label="CV Web Adresi"
                    variant="outlined"
                    className={classesName.formControl}
                  /></Col>
              </Row>
              <Row>
              <Col lg md sm={11} xs={11} mb={10}>
                  <TextField
                    id="outlined-basic"
                    label="Cep Telefon 1"
                    variant="outlined"
                    className={classesName.formControl}
                  />
                </Col>
                <Col lg md sm={11} xs={11} mb={10}>
                  <TextField
                    id="outlined-basic"
                    label="Cep Telefon 2"
                    variant="outlined"
                    className={classesName.formControl}
                  />
                </Col>
                <Col lg={4} md={4} sm={11} xs={11} mb={10}>
                  <TextField
                    id="outlined-basic"
                    name="finishdate"
                    label="Durum Tarihi"
                    type="date"
                    className={classesDate.formControl}
                    fullWidth
                    inputProps={{
                      max: new Date().toISOString().slice(0, 10),
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Col>
              </Row>
              <Row>
                  <Col lg md sm={11} xs={11} mb={10}>
                  <TextField
                    id="outlined-basic"
                    label="E-Mail 1"
                    variant="outlined"
                    className={classesName.formControl}
                  />
                  </Col>
                  <Col lg md sm={11} xs={11} mb={10}>
                  <TextField
                    id="outlined-basic"
                    label="E-Mail 2"
                    variant="outlined"
                    className={classesName.formControl}
                  />
                  </Col>
                  <Col lg={4} md={4} sm={11} xs={11} mb={10}>
            <TextField
              id="photo"
              name="photo"
              type="file"
              label="CV"
            />
                  </Col>
              </Row>

              <Row>
              <Col lg md sm xs mb={10}>
                <Button iconButton size="lg" className={classesButton.buttonClear}>
                <X size="16" className={classesButton.buttonIcon} />
                  TEMİZLE
                </Button>
                </Col>
                <Col lg md sm xs mb={10}>
                <Button iconButton size="lg" className={classesButton.buttonSave}>
                <Save size="16" className={classesButton.buttonIcon} />
                  KAYDET
                </Button>
              </Col>
              </Row>
            </FormGroup>
          </StyleFormGroup>
        </StyleSquare>
      </Container>
    </StyleComponent>
  );
};
export default PersonnelRegistration;
