import { FC } from "react";
import { StyledLoader } from "./style";

const Preloader: FC = () => {
    return (
        <StyledLoader>
        </StyledLoader>
    );
};

export default Preloader;
