 //export const SERVICEURL="https://localhost:44317/";
 //export const SERVICEURL="https://api.rdcboard.com/";prod ortam
 //export const v1link="https://test.rdcboard.com";
 export const v1link="https://rdcboard.com";
 //export const v2link="https://yenitest.rdcboard.com";
 export const v2link="https://yeni.rdcboard.com";
 export const SERVICEURL ="https://api.rdcboard.com/";
 export const teknolink = "https://tp.rdcboard.com/";
 


 